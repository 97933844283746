import React, { Component } from 'react';
import Radium from 'radium';


import { Link } from "react-router-dom";

import Style from "../Style";
import Header from './Header';
import moment from "moment";

import BLOG_POSTS from "../config/blogposts";

class ViewBlog extends Component {

	getBlog() {
		const blogId = parseInt(this.props.match.params.blogId);

		for (var i = 0; i < BLOG_POSTS.length; i++) {
			if (BLOG_POSTS[i].id === blogId) {
				console.log("found  it");
				return BLOG_POSTS[i];
			}
		}
		
		return null;
	}

	render() {
		var content  = null;
		const blog = this.getBlog();
		
		if (!blog) {
			return null;
		}

		return (
			<div style={localStyles.container}>
				<Header history={this.props.history}/>
				<div style={localStyles.content}>
					<div style={Style.global.pageTitle}>{blog.title}</div>
					<Link to="/">Home</Link> > <Link to="/blog">Blog</Link> > {blog.title}

					<div style={localStyles.blogContent}>
						<div style={localStyles.postedAt}>{moment(blog.date).format("DD MMM YYYY")} by {blog.author}</div>
						<blog.content history={this.props.history}/>
					</div>

				</div>
				
			</div>
		);
	}
}

const localStyles = {
	container: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: Style.colors.contentBackground,
		height: "100%",
		overflow: "auto"
	},
	content: {
		paddingLeft: 50,
		paddingRight: 50,
		paddingTop: 30,
		paddingBottom: 30
	},
	blogContent: {
		cursor: "pointer",
		backgroundColor: Style.colors.white,
		marginTop: 30,
		padding: 30,
		marginRight: 20,
		boxShadow: "0 0 20px 0 rgba(215,222,227,0.23)",
		borderRadius: 2,
		overflow: "hidden"
	},
	blogContainer: {
		marginTop: 50
	},
	blogCard: {
		backgroundColor: Style.colors.white,
		width: 250,
		marginTop: 30,
		padding: 30,
		marginRight: 20
	},
	postedAt: {
		color: Style.colors.border,
		fontWeight: "700",
		fontSize: 12,
		marginBottom: 10
	},
	summary: {
		fontSize: 14,
		lineHeight: 1.6

	}
};

export default Radium(ViewBlog);