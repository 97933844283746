import React, { Component } from 'react';
import Radium from 'radium';

import Logo from "../images/letusteach_logo@2x.png";
import ReactPlayer from "react-player";

import Style from "../Style";

import DownIcon from "../images/back@2x.png";

class VideoHeader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			muted: true
		};
	}

	componentDidMount() {
		
	}

	toggleMute() {
		this.setState({
			muted: !this.state.muted
		});
	}

	jumpToContent() {
		window.location.href = "#content";
	}

	goToJoin() {
		window.scrollTo(0, document.getElementById("join").offsetTop);
	}

	goToWhy() {
		window.scrollTo(0, document.getElementById("why").offsetTop);
	}

	goToBlog() {
		window.location.href = "/blog";
	}

	render() {
		return (
			<div style={localStyles.container}>

				<div style={localStyles.playerWrapper}>
					<ReactPlayer
						style={localStyles.reactPlayer}
						url='https://player.vimeo.com/video/314861801?&background=1'
						width='100%'
						loop={true}

						muted={this.state.muted}
						height='100%'
						vimeoConfig={{
							playerOptions: {
								background: 1,
								byline: 0,
								loop: 1
							}
						}}
						/>

					<div style={localStyles.topLinks}>
						<div style={localStyles.topLink} key="join" onMouseDown={() => this.goToJoin()}>Join</div>
						<div style={localStyles.divider}>|</div>
						<div style={localStyles.topLink} key="about" onMouseDown={() => this.goToWhy()}>Why?</div>
						<div style={localStyles.divider}>|</div>
						<div style={localStyles.topLink} key="blog" onMouseDown={() => this.goToBlog()}>Blog</div>	
					</div>

					<div style={localStyles.downIndicator}>
						<img alt="Down" src={DownIcon} style={localStyles.downIcon} onMouseDown={() => this.jumpToContent()}/>
					</div>
				</div>
				
				<div style={localStyles.header}>
					<img alt="Logo" src={Logo} style={localStyles.logo} onMouseDown={() => this.toggleMute()}/>
				</div>
			
			</div>
		);
	}
}

const localStyles = {
	container: {
		width: "100%",
	},
	videoHolder: {
		position: "relative"
	},
	header: {
		position: "absolute",
		left: 0,
		top: 0,
		padding: 40
	},
	logo: {
		width: 155.3,
		height: 44.13,
		"@media (max-width: 500px)": {
			display: "none"
		}
	},
	topLink: {
		textTransform: "uppercase",
		cursor: "pointer",
		opacity: "0.9",
		":hover": {
			opacity: 1
		}
	},
	divider: {
		color: Style.colors.primary,
		marginLeft: 10,
		marginRight: 10
	},
	topLinks: {
		position: "absolute",
		top: 40,
		left: 0,
		right: 40,
		height: 44,
		fontSize: 14,
		fontWeight: "500",
		display: "flex",
		color: Style.colors.white,
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		"@media (max-width: 500px)": {
			display: "none"
		}
	},
	playerWrapper: {
		position: "relative",
		paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
	}, 
	reactPlayer: {
		position: "absolute",
		top: 0,
		left: 0
	},
	downIcon: {
		height: 12.06,
		width: 24.13,
		cursor: "pointer"
	},
	downIndicator: {
		display: "flex",
		position: "absolute",
		bottom: 30,
		right: 0,
		left: 0,
		alignItems: "center",
		justifyContent: "center",
		"@media (max-width: 500px)": {
			display: "none"
		}
	}
};

export default Radium(VideoHeader);