import React, { Component } from 'react';
import Radium from 'radium';
import SignupButton from "../SignupButton";

import HttpServices from "../../services/HttpServices";
import CollaborationImage from "../../images/collaboration.png";

import Style from "../../Style";
import BlogImage from '../BlogImage';

class BlogPost2 extends Component {


	render() {
		return (
			<div style={localStyles.container}>
				<BlogImage image={CollaborationImage}/>
				<div style={Style.global.p}>
					The Education space is full of firms, ranging from large corporations through to niche EdTech startups, all competing to offer new products and services to those working in education - governors, headteachers, teachers, to name but a few. These are exciting times in the education space, with lots of verve and energy being expended to create new offerings, however further competition in education is not necessarily always a good thing. 
					A more fragmented landscape with a greater variety of offerings can lead to worse outcomes for the end customer - <b>teachers</b>.
				
					<ul>
						<li>Where do I go to find the best content for my pupils?</li>
						<li>What training should I take that is best aligned to my career aspirations?</li>
						<li>What tools are available that can help reduce the burden on my time?</li>
					</ul>
				</div>

				<div style={Style.global.p}>
					Rather than further fragment the landscape and drive greater confusion, we are looking to collaborate with like-minded firms to deliver a consolidated offering that enables teachers to be equipped with some of the best tools, capabilities, and collateral in order to deliver high quality education to their pupils. 
				</div>

				<div style={Style.global.p}>
					To evidence this mantra and ‘practice what we preach’, we have created a collaborative working community formed of experts from a number of firms and third parties who are working to deliver this new offering to those working in education.
				</div>

				<div style={Style.global.p}>
					We are on the look-out for other firms who are open to collaborating, rather than competing, in order to truly solve problems within the sector and enabling teachers to excel at what they like doing best - <b>teaching</b>. 
				</div>

				<div style={Style.global.p}>
				If you know of or work for a like-minded firm that would like to get involved with this exciting initiative please get in touch! 
				</div>
				
			</div>
		);
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "column"
	}
};

export default Radium(BlogPost2);