const Career = require("../images/screens/career@2x.png");
const Feed1 = require("../images/screens/feed1@2x.png");
const Feed2 = require("../images/screens/feed2@2x.png");
const FindMentor = require("../images/screens/findmentor@2x.png");
const FindRoles1 = require("../images/screens/findroles@2x.png");
const FindRoles2 = require("../images/screens/findroles2@2x.png");
const Profile = require("../images/screens/profile@2x.png");
const Students = require("../images/screens/students@2x.png");
const TrainingMarketplace = require("../images/screens/trainingmarketplace@2x.png");
const TrainingNotification = require("../images/screens/trainingnotification@2x.png");
const Wellbeing1 = require("../images/screens/wellbeing1@2x.png");
const Wellbeing2 = require("../images/screens/wellbeing2@2x.png");

module.exports = [
    {
        id: "profile_page_1",
        title: "Your Profile",
        description: "Providing a private teacher-only safe place for you to setup your profile, choose your interests, add your skills and tell your community what matters to you.",
        screens: [Profile]
    },
    {
        id: "find_mentors_page_1",
        title: "Find mentors",
        description: "Find mentors that share similar interests to you, or have experience that you'd like to learn from. Mentors could be in any part of the country, all connected through our platform.",
        screens: [FindMentor]
    },
    {
        id: "find_roles_page_1",
        title: "Find roles",
        description: "Find roles matched to your interests and automatically be notified of jobs that might interest you. Tell schools that you are interested in them, and be automatically notified when opportunities become available.",
        screens: [FindRoles2, FindRoles1]
    },
    {
        id: "community_page_1",
        title: "Community",
        description: "Share information with your community, ask questions and get advice. Post anonymously if you don't feel comfortable asking certain questions.",
        screens: [Feed1, Feed2]
    },
    {
        id: "wellbeing_page_1",
        title: "Wellbeing",
        description: "Track and understand your wellness and workload. Integrations to tools that help you focus & unwind.",
        screens: [Wellbeing1, Wellbeing2]
    },
    {
        id: "career_page_1",
        title: "Your Career",
        description: "Set your career goals and aims, and receive personalised job & training recommendations to help meet those goals",
        screens: [Career]
    },
    {
        id: "training_offers_marketplace_page_1",
        title: "Training & Offers Marketplace",
        description: "Receive tailored offers for training courses relevant to you. Additionally access a wide array of exclusive teacher offers.",
        screens: [TrainingMarketplace]
    },
    {
        id: "training_notification_page_1",
        title: "Mandatory Training Notifications",
        description: "Receive notifications when mandatory training is due, and quickly and easily access that training. If you're a headteacher you'll be able to see how many of your teachers have completed their training.",
        screens: [TrainingNotification]
    },
    {
        id: "students_page_1",
        title: "See how your students are doing",
        description: "A school-wide dashboard that provides insight into how students are doing by collating information from teachers and intelligently deriving insights. Easily connect with parents to help engage them or discuss issues.",
        screens: [Students]
    },

];