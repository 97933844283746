import React, { Component } from 'react';
import Radium from 'radium';

import Checkbox from "./Checkbox";

class GdprCheckbox extends Component {
	render() {
		return  (
			<div style={localStyles.container}>
				
				<Checkbox
					checked={this.props.checked}
					onToggle={(value) => this.props.onToggleChecked(value)}
				/>
				<div style={localStyles.label}>
					I'd like to receive emails from the Let Us Teach movement
				</div>
			
			</div>
		);
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		userSelect: "none"
	},
	label: {
		fontSize: 12
	}
};

export default Radium(GdprCheckbox);