import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";

class TextField extends Component {

	onChange(evt) {
		this.props.onChange(evt.target.value);
	}

	render() {
		return  (
			<div style={localStyles.container}>
				<div style={localStyles.label}>{this.props.label}</div>
				<input 
					style={localStyles.input} 
					type="text"
					placeholder={this.props.placeholder}
					value={this.props.value}
					onChange={(evt) => this.onChange(evt)}/>
			</div>
		);
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
		marginTop: 20
	},
	label: {
		marginBottom: 5,
		fontSize: 15,
		fontWeight: "700"
	},
	input: {
		borderStyle: "solid",
		borderColor: Style.colors.border,
		borderWidth: 1,
		borderRadius: 2,
		padding: 10,
		fontSize: 15,
		width: 260,
		outline: "none"
	}
};

export default Radium(TextField);