const axios = require("axios");
const UrlConfig = require("./UrlConfig");

const BASE_URL = UrlConfig.HTTP;
const S3_URL = UrlConfig.S3;

const DEFAULT_TIMEOUT = 20000;
const STORAGE_TOKEN_KEY = "token";
const STORAGE_USER_KEY = "user";

var getErrorMessage = function(err) {
  if (err && err.response && err.response.data && err.response.data.error) {
    return err.response.data.error;
  } else {
    console.log(err);
    return "An unexpected error occurred";
  }
};

var getResponseData = function(res) {
  if (res && res.data) {
    return res.data;
  } else {
    return res;
  }
};

module.exports = {
  user: null,
  token: null,

  STORAGE_TOKEN_KEY: STORAGE_TOKEN_KEY,
  STORAGE_USER_KEY: STORAGE_USER_KEY,

  getBaseUrl: function() {
    return BASE_URL;
  },

  getS3Url() {
    return S3_URL;
  },

  getChatImageUrl(id) {
    return S3_URL + "/chats/" + id;
  },

  getToken() {
    return this.token;
  },

  createHeaders: function() {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (this.token) {
      headers["x-user-token"] = this.token;
    }

    return headers;
  },

  makeRequest: function(url, method, body, excludeBaseUrl) {
      return new Promise((resolve, reject) => {
        var urlToUse;

        if (excludeBaseUrl) {
            urlToUse = url;
        } else {
            urlToUse = BASE_URL + url;
        }

        axios({
            url: urlToUse,
            method: method,
            data: body,
            timeout: DEFAULT_TIMEOUT,
            headers: this.createHeaders()
        })
        .then((result) => {
            return resolve(getResponseData(result));
        })
        .catch((e) => {
            return reject(getErrorMessage(e));
        });
      });
  },

  post: function(url, body, excludeBaseUrl) {
      return this.makeRequest(url, "post", body, excludeBaseUrl);
  },

  put: function(url, body, excludeBaseUrl) {
    return this.makeRequest(url, "put", body, excludeBaseUrl);
  },

  delete: function(url, body, excludeBaseUrl) {
    return this.makeRequest(url, "delete", body, excludeBaseUrl);
  },

  get: function(url, callback, excludeBaseUrl) {
    return this.makeRequest(url, "get", null, excludeBaseUrl);
  },

  setUserAndToken: function(user, token) {
    window.localStorage.user = JSON.stringify(user);
    window.localStorage.token = token;
    this.user = user;
    this.token = token;
  },

  setToken(token) {
    this.token = token;
  },

  clearToken() {
    this.token = null;
  },

  clearUserAndToken: function() {
    this.user = null;
    this.token = null;
  },

  uploadFile: function(
    uploadUrl,
    fileUrl,
    fileField,
    callback,
    excludeBaseUrl
  ) {
    let data = new FormData();
    if (fileUrl) {
      data.append(fileField, {
        uri: fileUrl,
        name: "image.jpg",
        type: "image/jpg"
      });
    }

    const config = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "x-user-token": this.token
      },
      body: data
    };

    var urlToUse;

    if (excludeBaseUrl) {
      urlToUse = uploadUrl;
    } else {
      urlToUse = BASE_URL + uploadUrl;
    }

    fetch(urlToUse, config)
      .then(response => {
        // if (response.status === 404 || response.status === 200 || resoib) {
        return response.json();
        //}
      })
      .then(responseJson => {
        if (responseJson.type === "error" || responseJson.error) {
          return callback(responseJson);
        } else {
          return callback(null, responseJson);
        }
      })
      .catch(error => {
        console.error(error);
      });
  },

  getProfilePicUrl(picId) {
    return S3_URL + "/profile_pics/" + picId;
  },

  getThankYouBadgeUrl(badgeId) {
    return S3_URL + "/badges/" + badgeId + "@2x.png";
  }
};