import React, { Component } from 'react';
import Radium from 'radium';
import BlogPost1 from "./blogposts/BlogPost1";

import { Link } from "react-router-dom";

import Style from "../Style";
import Header from './Header';
import moment from "moment";

import BLOG_POSTS from "../config/blogposts";

class Blog extends Component {

	onGoToBlog(blog) {
		this.props.history.push("/blog/" + blog.id);
	}

	render() {
		return (
			<div style={localStyles.container}>
				<Header history={this.props.history}/>
				<div style={localStyles.content}>
					<div style={Style.global.pageTitle}>Blog</div>
					<Link to="/">Home</Link> > Blog

					<div style={localStyles.blogCards}>
						{BLOG_POSTS.map((blogPost) => {
							return (
								<div key={blogPost.id} style={localStyles.blogCard} onClick={() => this.onGoToBlog(blogPost)}>
									<div style={[localStyles.blogImage, {backgroundImage: "url('" + blogPost.image + "')"}]}></div>
									<div style={localStyles.blogCardContent}>
										<div style={Style.global.h1}>{blogPost.title}</div>
										<div style={localStyles.postedAt}>{moment(blogPost.date).format("DD MMM YYYY")} by {blogPost.author}</div>
										<div style={localStyles.summary}>{blogPost.summary}</div>
									</div>
								</div>
							);
						})}
					</div>

				</div>
				
			</div>
		);
	}
}

/*
	<div style={localStyles.blogContent}>
		<blogPost.content history={this.props.history}/>
	</div>
*/

const localStyles = {
	container: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: Style.colors.contentBackground,
		height: "100%",
		overflow: "auto"
	},
	blogImage: {
		height: 140,
		width: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat"
	},
	content: {
		paddingLeft: 50,
		paddingRight: 50,
		paddingTop: 30,
		paddingBottom: 30
	},
	blogCards: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		flexDirection: "column",
		flexDirection: "row"
	},
	blogContainer: {
		marginTop: 50
	},
	blogCard: {
		cursor: "pointer",
		backgroundColor: Style.colors.white,
		width: 280,
		marginTop: 30,
		marginRight: 20,
		boxShadow: "0 0 20px 0 rgba(215,222,227,0.23)",
		borderRadius: 2,
		overflow: "hidden",
		transition: "all 0.1s ease-in-out"
	},
	blogCardContent: {
		padding: 30
	},
	postedAt: {
		color: Style.colors.border,
		fontWeight: "700",
		fontSize: 12,
		marginBottom: 10
	},
	summary: {
		fontSize: 14,
		lineHeight: 1.6

	}
};

export default Radium(Blog);