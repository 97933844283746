import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";

import Logo from "../images/coloured_logo@2x.png";

class Header extends Component {

	goHome() {
		this.props.history.push("/");
	}

	renderRightSide() {
		if (this.props.rightSide) {
			return (
				<div style={localStyles.rightSide}>
					{this.props.rightSide()}
				</div>
			);
		}

		return null;
	}

	render() {
		return (
			<div style={localStyles.container}>
				<img alt="Logo" src={Logo} style={localStyles.logo} onClick={() => this.goHome()}/>
				{this.renderRightSide()}
			</div>
		);
	}
}

const localStyles = {

	container: {
		backgroundColor: Style.colors.white,
		paddingBottom: 20,
		paddingTop: 20,
		width: "100vw",
		borderBottomWidth: 1,
		borderBottomColor: Style.colors.border,
		borderBottomStyle: "solid",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},

	rightSide: {
		marginRight: 50
	},

	logo: {
		width: 155.3,
		height: 44.13,
		marginLeft: 50,
		cursor: "pointer"
	}
};

export default Radium(Header);