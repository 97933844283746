import React, { Component } from 'react';
import Radium from 'radium';

import Style from  "../Style";
import Header from "./Header";
import Button from './Button';

class ScreensGetStartedPage extends Component {

	onShowFirstScreen() {
		this.props.history.push("/screen/0");
	}

	render() {
		return (
			<div style={localStyles.container}>
				<Header history={this.props.history}/>
				<div style={localStyles.content}>
					<div style={Style.global.h1}>We'd love to show you some of our <span style={Style.global.primaryText}>ideas</span></div>
					<div style={Style.global.p}>Based on conversations we've had with teachers like you, we've produced some early screen designs. We'd love to see what you think of them - good or bad. Remember we want to build a service for you, so the more feedback you can give us, the more relevant we can make it to you.</div>
					<Button label="Show me the ideas >" onClick={() => this.onShowFirstScreen()}/>
				</div>
			</div>
		);
	}
}

const localStyles = {
	content: {
		padding: 50
	},
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column"
	}
};

export default Radium(ScreensGetStartedPage);