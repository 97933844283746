import React, { Component } from 'react';
import Radium from 'radium';

import TextField from "./TextField";

import Style from "../Style";

class DropDownField extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showOther: false
		};
	}

	onChange(evt) {
		this.props.onChange(evt.target.value);

		if (evt.target.value === "Other") {
			this.setState({
				showOther: true
			});
		} else {
			this.setState({
				showOther: false
			});
		}
	}

	renderOther() {
		if (this.state.showOther) {
			return (
				<div>
					<TextField
						placeholder="Please provide details..."
						value={this.props.value}
						onChange={this.props.onChange}
					/>
				</div>
			)
		}
	}

	render() {
		return  (
			<div style={localStyles.container}>
				<div style={localStyles.label}>{this.props.label}</div>
				<select 
					style={localStyles.input} 
					placeholder={this.props.placeholder}
					value={this.props.value}
					onChange={(evt) => this.onChange(evt)}>
						<option value="">Please choose...</option>
					
						{this.props.options.map((option) => {
							return (
								<option key={option} value={option}>{option}</option>
							);
						})}

				</select>
				{this.renderOther()}
			</div>
		);
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
		marginTop: 20
	},
	label: {
		marginBottom: 5,
		fontSize: 15,
		fontWeight: "700"
	},
	input: {
		borderStyle: "solid",
		borderColor: Style.colors.border,
		backgroundColor: Style.colors.white,
		borderWidth: 1,
		borderRadius: 2,
		height: 40,
		fontSize: 15,
		paddingLeft: 10,
		width: 260,
		outline: "none"
	}
};

export default Radium(DropDownField);