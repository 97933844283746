import React, { Component } from 'react';
import Radium from 'radium';

import TextField from "./TextField";
import Button from "./Button";

import Style from "../Style";
import HttpServices from "../services/HttpServices";
import PersonalDataFields from "./PersonalDataFields";
import GdprCheckbox from './GdprCheckbox';

class ThankYouCollectDetails extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			typeOfSchool: "",
			location: "",
			phase: "",
			position: "",
			lengthOfService: "",
			gdprChecked: false
		};
	}

	onToggleGdprChecked(value) {
		this.setState({
			gdprChecked: value
		});
	}

	onGoHome() {
		this.props.history.push("/");
	}

	onChangeField(field, value) {
		var updateObj = {};
		updateObj[field] = value;
		this.setState(updateObj);
	}

	async onSave() {
		if (this.state.email) {
			await HttpServices.post("/personal/fromanonymous", {
				anonymous_id: window.localStorage.email,
				email: this.state.email,
				name: this.state.name,
				location: this.state.location,
				type_of_school: this.state.typeOfSchool,
				phase: this.state.phase,
				position: this.state.position,
				length_of_service: this.state.lengthOfService,
				gdpr: this.state.gdprChecked
			});

			window.localStorage.email = this.state.email;
			this.props.history.push("/");
		}
	}

	render() {
		if (!window.localStorage.email || (window.localStorage.email && window.localStorage.email.indexOf("@") < 0)) {
			return (
				<div style={localStyles.container}>
					
					<TextField 
						label="Email" 
						placeholder="Your email"
						value={this.state.email}
						onChange={(value) => this.onChangeField("email", value)}/>

					<GdprCheckbox
						checked={this.state.gdprChecked}
						onToggleChecked={(value) => this.onToggleGdprChecked(value)}
					/>

					<PersonalDataFields
						onChangeField={(field, value) => this.onChangeField(field, value)}
					/>

					<Button
						label="Save"
						onClick={() => this.onSave()}
					/>
				
				</div>
			);
		} else {
			return (
				<Button 
						label="Back to the main page"
						onClick={() => this.onGoHome()}
						backgroundColor={Style.colors.secondary}/>
			);
		}
		
	}
}

const localStyles = {
	container: {
		marginTop: 20
	}
};

export default Radium(ThankYouCollectDetails);