import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";

import ScreenConfig from "../config/screens";

import Header from "./Header";
import ScreenRater from './ScreenRater';
import Progress from "./Progress";
import ScreensProgressNumber from "./ScreensProgressNumber";

import uuid from "uuid";

import HttpServices from "../services/HttpServices";

const SCREEN_DELAY = 3000;

class ScreenPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentScreen: ScreenConfig[parseInt(this.props.match.params.id)],
			currentScreenIndex: parseInt(this.props.match.params.id),
			currentScreenImageIndex: 0
		};
	}

	componentWillMount() {
		if (!window.localStorage.email || (!this.props || !this.props.match || !this.props.match.params || !this.props.match.params.id)) {
			window.localStorage.email = uuid.v4();
		}

		if (ScreenConfig.length <= this.props.match.params.id) {
			// REDIRECT TO END
			this.props.history.push("/");
		}
	}

	incrementScreenIndex() {
		var newIndex = this.state.currentScreenImageIndex + 1;
		if (newIndex >= this.state.currentScreen.screens.length) {
			newIndex = 0;
		}

		this.setState({
			currentScreenImageIndex: newIndex
		});
	}

	componentDidMount() {
		if (this.state.currentScreen.screens.length > 1) {
			this._timer = setInterval(() => {
				this.incrementScreenIndex()
			}, SCREEN_DELAY);
		}
	}

	componentWillUnmount() {
		if (this._timer) {
			clearInterval(this._timer);
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			currentScreen: ScreenConfig[parseInt(nextProps.match.params.id)],
			currentScreenIndex: parseInt(nextProps.match.params.id)
		});
	}

	onNext(rating, comments) {
		// Save
		if (rating !== null || (comments !== null && comments.length > 0)) {
			HttpServices.post("/screen", {
				email: window.localStorage.email,
				screen_id: this.state.currentScreen.id,
				rating: rating,
				comments: comments
			});	
		}
		
		var nextIndex = this.state.currentScreenIndex + 1;

		if (nextIndex < ScreenConfig.length) {
			this.props.history.push("/screen/" + nextIndex);
		} else {
			this.props.history.push("/thankyou");
		}
	}

	renderScreen() {
		return (
			<img 
				alt="Screen" 
				src={this.state.currentScreen.screens[this.state.currentScreenImageIndex]} 
				style={localStyles.screen}/>
		);
	}

	renderRightSide() {
		return (
			<ScreensProgressNumber
				currentIndex={this.state.currentScreenIndex}
				total={ScreenConfig.length}
			/>
		)
	}

	render() {
		return (
			<div key={this.state.currentScreenIndex} style={localStyles.container}>
				<Header history={this.props.history} rightSide={() => this.renderRightSide()}/>
				<Progress totalScreens={ScreenConfig.length} currentIndex={this.state.currentScreenIndex}/>
				<div style={localStyles.contentContainer}>
					<div style={localStyles.content}>
						<div style={Style.global.h1}>{this.state.currentScreen.title}</div>
						<div style={Style.global.description}>{this.state.currentScreen.description}</div>
						<div style={localStyles.showIfFullScreen}>
							<ScreenRater onNext={(rating, comments) => this.onNext(rating, comments)}/>
						</div>
					
					</div>
					<div style={localStyles.screenContainer}>
						{this.renderScreen()}
					</div>
				</div>

				<div style={[localStyles.contentContainer, localStyles.showIfMobile]}>
					<ScreenRater onNext={(rating, comments) => this.onNext(rating, comments)}/>
					
				</div>

				
				
			</div>
		);
	}
}

const localStyles = {
	container: {
		width: "100%"
	},
	contentContainer: {
		display: "flex",
		flexDirection: "row",
		padding: 50,
		maxWidth: 1000,
		"@media (max-width: 500px)": {
			flexDirection: "column",
			paddingBottom: 0
		}
	},
	showIfFullScreen: {
		"@media (max-width: 500px)": {
			display: "none"
		}
	},
	showIfMobile: {
		"@media (min-width: 500px)": {
			display: "none",
			paddingTop: 0
		}
	},
	screenContainer: {
		paddingLeft: 50,
		paddingRight: 50,
		"@media (max-width: 500px)": {
			paddingLeft: 0,
			paddingRight: 0,
			marginTop: 50
		}
	},
	screen: {
		width: 259,
		height: 535
	},
	content: {
		flex: 1
	}
};

export default Radium(ScreenPage);