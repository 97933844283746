import React, { Component } from 'react';
import Radium from 'radium';
import SignupButton from "../SignupButton";

import InterviewsImage from "../../images/interviews@2x.png";
import FindingsImage from "../../images/findings@2x.png";
import PropositionsImage from "../../images/propositions@2x.png";

import HttpServices from "../../services/HttpServices";

import Style from "../../Style";
import BlogImage from '../BlogImage';

class BlogPost1 extends Component {

	onSignup(email, gdpr) {
		window.localStorage.email = email;

		HttpServices.post("/email", {
			email: email,
			gdpr: gdpr
		});

		this.props.history.push("/confirmdetails");
	}

	render() {
		return (
			<div style={localStyles.container}>
				<div style={Style.global.p}>
					Over the last couple of weeks, we have been trying to understand how we can better help teachers do what teachers do best: teach!
					We have spoken with over 60 teachers and educators, with varying experience across school type, key stage, tenure and leadership. Across all our interviews, what was most obvious is that you care about your students above all else. And there are some common and pressing needs that we want to help address. 
				</div>
				<BlogImage image={InterviewsImage}/>
				<div style={Style.global.p}>
					We need to make teachers’ lives easier. This means reducing time spent on admin outside the classroom; the marking, lesson planning and data entry. We want to strip out data for data’s sake, and ensure that whatever you do outside the classroom is meaningful for the students in it. 
				</div>
				<div style={Style.global.p}>
					We know you want to be the best teacher you can be. But this means something different for every teacher; professional development isn’t just progression to senior leadership positions but is also about excelling in the classroom. You told us that this can be hard initially; early career teachers need additional support to boost their confidence in their role as educators. So we want to ensure all of you can access the training and development opportunities you need.
				</div>
				<div style={Style.global.p}>
				Lastly, in the interests of a true school community, you want to be able to engage the parents of your students and work together with them to support your students as best you can.  
				</div>
				<BlogImage image={FindingsImage}/>
				<div style={Style.global.p}>
					We’ve developed some initial ideas about how we can help to solve these problems. For a sneak peek on what we’ve done so far, <a href="/screen/0">click here</a> – let us know what you think!
				</div>
				<div style={Style.global.p}>
					You’ve told us that digital tools come and go, and can sometimes even create more work, so we are going to refine our thinking to ensure we create something simple, usable and lasting. 
				</div>
				<BlogImage image={PropositionsImage}/>
				<div style={Style.global.p}>
					The best solution will be one that you have helped create - so please continue to support us by signing up below.
				</div>
				<SignupButton
					onSignup={(email, gdpr) => this.onSignup(email, gdpr)}
				/>
				
			</div>
		);
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "column"
	}
};

export default Radium(BlogPost1);