import React, { Component } from 'react';
import Radium from 'radium';

import Button from "./Button";

import Style from "../Style";

const RATINGS = [
	{id: 1, title: "Don't like", rating: 1},
	{id: 2, title: "Not sure", rating: 2},
	{id: 3,  title: "Like", rating: 3}
];

class WhatDoYouThink extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedRating: null,
			comments: ""
		};
	}

	onSelectRating(rating) {
		this.setState({
			selectedRating: rating
		});
	}

	onNext() {
		var rating = null;

		if (this.state.selectedRating) {
			rating = this.state.selectedRating.rating;
		}

		this.props.onNext(rating, this.state.comments);
	}

	onCommentsChange(evt) {
		this.setState({
			comments: evt.target.value
		});
	}

	render() {
		return (
			<div style={localStyles.container}>
				<div style={Style.global.label}>
					What do you think?
				</div>
				<div style={localStyles.rater}>
					{RATINGS.map((rating, index) => {
						var ratingStyles = [localStyles.rating];

						if (index === RATINGS.length - 1) {
							ratingStyles.push(localStyles.ratingNoBorder);
						}

						if (this.state.selectedRating && rating.id === this.state.selectedRating.id) {
							ratingStyles.push(localStyles.ratingOn);
						}

						return (
							<div 
								key={rating.id} 
								style={ratingStyles}
								onMouseDown={() => this.onSelectRating(rating)}>{rating.title}</div>
						);
					})}
				</div>
				<div style={localStyles.commentsHolder}>
					<div style={Style.global.label}>
						Any comments?
					</div>
					<textarea style={localStyles.comments} value={this.state.comments} onChange={(evt) => this.onCommentsChange(evt)}></textarea>
				</div>

				<Button label="Next >" onClick={() => this.onNext()}/>
				
			</div>
		);
	}
}

const localStyles = {
	rater: {
		position: "relative",
		marginTop: 10,
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: Style.colors.primary,
		borderRadius: 4,
		overflow: "hidden",
		display: "flex",
		flexDirection: "row",
		width: 242
	},
	comments: {
		width: 235,
		borderStyle: "solid",
		borderColor: Style.colors.primary,
		borderWidth: 1,
		borderRadius: 2,
		outline: "none",
		fontSize: 14,
		padding: 5,
		height: 50
	},
	like: {
		position: "absolute",
		top: 50,
		right: 0,
		zIndex: 999
	},
	commentsHolder: {
		marginTop: 30
	},
	ratingOn: {
		backgroundColor: Style.colors.primary,
		color: Style.colors.white
	},
	ratingNoBorder: {
		borderRightStyle: "none"
	},
	rating: {
		cursor: "pointer",
		width: 80,
		fontSize: 14,
		height: 40,
		borderRightWidth: 1,
		borderRightColor: Style.colors.primary,
		borderRightStyle: "solid",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	}
};

export default Radium(WhatDoYouThink);