import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";

class Button extends Component {
	render() {
		var containerStyles = [localStyles.container];

		if (this.props.backgroundColor) {
			containerStyles.push({
				backgroundColor: this.props.backgroundColor
			});
		}

		return (
			<div style={containerStyles} onMouseDown={this.props.onClick}>
				{this.props.label}
			</div>
		);
	}
}

const localStyles = {
	container: {
		borderRadius: 2,
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 10,
		paddingBottom: 10,
		display: "inline-block",
		color: Style.colors.white,
		backgroundColor: Style.colors.primary,
		cursor: "pointer",
		fontWeight: "700",
		marginTop: 20,
		userSelect: "none"
	}
};

export default Radium(Button);