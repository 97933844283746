import React, { Component } from 'react';
import Radium from 'radium';

import Tick from "../images/tick@2x.png";

import Style from "../Style";

class Checkbox extends Component {

	onToggle() {
		this.props.onToggle(!this.props.checked);
	}

	render() {
		var tick = null;

		if (this.props.checked) {
			tick = (
				<img alt="Tick" src={Tick} style={localStyles.tick}/>
			);
		}

		return (
			<div style={localStyles.container} onClick={() => this.onToggle()}>
				{tick}
			</div>
		);
	}
}

const localStyles = {
	container: {
		width: 20,
		height: 20,
		marginRight: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		border: Style.colors.border,
		borderRadius: 2,
		borderStyle: "solid",
		borderWidth: 1,
		cursor: "pointer"
	},
	tick: {
		width: 12,
		height: 10
	}
};

export default Radium(Checkbox);