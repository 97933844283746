const COLORS  = {
    black: "#000000",
    white: "#ffffff",
    border: "#DADADA",
    primary: "#02A1C0",
    secondary: "#FD6E84",
    darkBackground: "#3E3E3E",
    lightBackground: "rgba(10,10,10,0.01)",
    contentBackground: "#F5F5F5"
};

const FONTS = {

};

const GLOBAL = {
    bold: {
        fontWeight: "700"
    },
    button: {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 14,
        fontWeight: "700",
        userSelect: "none",
        cursor: "pointer"
    },
    label: {
        fontSize: 15,
        fontWeight: "700",
        marginBottom: 5
    },
    h1: {
        fontSize: 24,
        color: COLORS.black,
        fontWeight: "700",
        marginBottom: 5
    },
    pageTitle: {
        fontSize: 40,
        color: COLORS.black,
        fontWeight: "700",
        marginBottom: 10
    },
    description: {
        marginTop: 10,
        fontSize: 18,
        color: COLORS.black
    },
    p: {
        marginBottom: 10,
        marginTop: 10,
        opacity: 0.8,
        lineHeight: 1.5
    },
    primaryText: {
        color: COLORS.primary
    },
    secondaryText: {
        color: COLORS.secondary
    },
    blogImage: {
        marginTop: 20,
        marginBottom: 20,
        width: "90%",
        height: 300,
        backgroundSize: "contain",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat"
    }
};

module.exports = {
    colors: COLORS,
    fonts: FONTS,
    global: GLOBAL
};