import React, { Component } from 'react';
import Radium from 'radium';

import Header from "./Header";

import Style from "../Style";

import HttpServices from "../services/HttpServices";

import Button from "./Button";
import PersonalDataFields from './PersonalDataFields';

class ConfirmDetailsPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: "",
			typeOfSchool: "",
			location: "",
			phase: "",
			position: "",
			lengthOfService: ""
		};
	}

	onChangeField(field, value) {
		var updatedState = this.state;
		updatedState[field] = value;
		this.setState(updatedState);
	}

	onNext() {
		console.log(this.state);
		// TODO Save

		HttpServices.post("/personal", {
			email: window.localStorage.email,
			name: this.state.name,
			type_of_school: this.state.typeOfSchool,
			location: this.state.location,
			phase: this.state.phase,
			position: this.state.position,
			length_of_service: this.state.lengthOfService
		});

		this.props.history.push("/screens");
	}

	render() {
		return (
			<div>
				<Header history={this.props.history}/>
				<div style={localStyles.content}>
					<div style={Style.global.h1}><span style={Style.global.primaryText}>Thanks</span>, let us confirm a few details</div>
					<div>
						<PersonalDataFields
							onChangeField={(field, value) => this.onChangeField(field, value)}
						/>

						<Button label="Next" onClick={() => this.onNext()}/>
					</div>
				</div>
			</div>
		)
	}
}

const localStyles = {
	content: {
		padding: 50
	}
};

export default Radium(ConfirmDetailsPage);