import React, { Component } from 'react';
import Radium, { StyleRoot } from 'radium';

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Placeholder from "./Placeholder";
import ScreenPage from "./ScreenPage";
import ConfirmDetailsPage from "./ConfirmDetailsPage";
import ScreensGetStartedPage from "./ScreensGetStartedPage";
import ThankYou from "./ThankYou";
import ScrollToTop from "react-router-scroll-top";
import Login from "./Login";
import Blog from './Blog';
import ViewBlog from "./ViewBlog";

class Main extends Component {
	render() {
		return (
			<StyleRoot style={localStyles.container}>
				<BrowserRouter>
						<ScrollToTop>
							<Switch>
								<Route path="/" exact component={Placeholder}/>
								<Route path="/confirmdetails" exact component={ConfirmDetailsPage}/>
								<Route path="/screens" exact component={ScreensGetStartedPage}/>
								<Route path="/screen/:id" exact component={ScreenPage}/>
								<Route path="/thankyou" exact component={ThankYou}/>
								<Route path="/login" exact component={Login}/>
								<Route path="/blog" exact component={Blog}/>
								<Route path="/blog/:blogId" exact component={ViewBlog}/>
							</Switch>
						</ScrollToTop>
					
				</BrowserRouter>
			</StyleRoot>
			
		);
	}
}

const localStyles = {
	container: {
		display: "flex"
	}
};

export default Radium(Main);