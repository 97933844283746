import React, { Component } from 'react';
import Radium from 'radium';
import SignupButton from "../SignupButton";

import HttpServices from "../../services/HttpServices";

import Style from "../../Style";
import BlogImage from '../BlogImage';
import TrainingImage from "../../images/screens/trainingmarketplace.png";

class BlogPost3 extends Component {

	onSignup(email, gdpr) {
		window.localStorage.email = email;

		HttpServices.post("/email", {
			email: email,
			gdpr: gdpr
		});

		this.props.history.push("/confirmdetails");
	}

	render() {
		return (
			<div style={localStyles.container}>
				<BlogImage image={TrainingImage}/>
				<div style={Style.global.p}>
					In our last blog post, we shared with you that it's not always easy for teachers to access the training and development they need. Both new and experienced teachers don't always feel like they are given the professional support to help them thrive and grow.
				</div>
				<div style={Style.global.p}>
					We continued to ask teachers about their experience with learning and development, and found that the information provided for teachers about both their immediate needs, and their broader career paths isn't always clear or even always available. Our first aim is therefore to make access to high quality, impactful training affordable and accessible no matter where your school is, and to make it relevant to your individual needs as a teacher.
				</div>
				<div style={Style.global.p}>
					Instead of trawling through different websites for relevant CPD materials or courses, these resources should come from the same source, and should be built around an understanding of who you are as a teacher and what you need.
				</div>
				<div style={Style.global.p}>
					This would also make it easier for school leaders to better understand what their teachers' individual CPD needs are, so that they can coach and guide their progress as they grow and develop.
				</div>
				<div style={Style.global.p}>
					This teacher-centric platform need not be limited to just learning and development; eventually we want a teacher to access a single place for personalised training, job opportunities, mentoring connections and lesson planning resources.
				</div>
				<div style={Style.global.p}>
					For us, this means a platform for teachers, which places them at the centre. We're still working on what exactly this might look like - and your input helps!
				</div>
				
			</div>
		);
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "column"
	}
};

export default Radium(BlogPost3);