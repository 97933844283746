import React, { Component } from 'react';
import Radium from 'radium';

import { Link } from "react-router-dom";

import Style from "../Style";


class BlogImage extends Component {

	render() {
		return (
			<div style={[Style.global.blogImage, {backgroundImage: "url('" + this.props.image + "')"}]}/>
		);
	}

};

export default Radium(BlogImage);