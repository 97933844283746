import React, { Component } from 'react';
import Radium from 'radium';
import Style from "../Style";

import Logo from "../images/coloured_logo@2x.png";

class Footer extends Component {
	render() {
		return (
			<div style={localStyles.container}>
				<img alt="Logo" src={Logo} style={localStyles.logo}/>
			</div>
		);
	}
}

const localStyles = {
	container: {
		marginTop: 200,
		display: "flex",
		width: "80%",
		paddingTop: 30,
		paddingBottom: 30,
		justifyContent: "center",
		borderTopColor: Style.colors.border,
		borderTopWidth: 1,
		borderTopStyle: "solid",
		borderBottomColor: Style.colors.border,
		borderBottomWidth: 1,
		borderBottomStyle: "solid"
	},
	logo: {
		width: 155.3,
		height: 44.13
	},
};

export default Radium(Footer);