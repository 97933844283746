import React, { Component } from 'react';
import Radium from 'radium';
import Header from './Header';


class Login extends Component {
	render() {
		return (
			<div>
				<Header/>
			</div>
		);
	}
}

export default Radium(Login);