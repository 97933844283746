import React, { Component } from 'react';
import Radium from 'radium';

import WhatDoYouThink from "./WhatDoYouThink";

class ScreenRater extends Component {
	render() {
		return (
			<div style={localStyles.container}>
				<WhatDoYouThink onNext={this.props.onNext}/>
			</div>
		);
	}
}

const localStyles = {
	container: {
		marginTop: 30,
		marginBottom: 50
	}
};

export default Radium(ScreenRater);