import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";
import SignupButton from './SignupButton';
import VideoHeader from "./VideoHeader";
import ReactPlayer from "react-player";
import Footer from "./Footer";
import Button from "./Button";

import BLOG_POSTS from "../config/blogposts";

import IdeasImage from "../images/ideas@2x.png";

import HttpServices from "../services/HttpServices";

class Placeholder extends Component {
	
	componentDidMount() {
		HttpServices.post("/tracking/visit", {
			page_id: "home"
		});
	}

	onSignup(email, gdpr) {
		window.localStorage.email = email;

		HttpServices.post("/email", {
			email: email,
			gdpr: gdpr
		});

		this.props.history.push("/confirmdetails");
	}

	goToRateIdeas() {
		this.props.history.push("/screen/0");
	}

	goToBlog() {
		this.props.history.push("/blog");
	}

	render() {
		return (
			<div style={localStyles.container}>
				
				<VideoHeader/>

				<a name="content"/>

				<div style={localStyles.content}>
					<div style={localStyles.innerContent}>
						<div style={localStyles.leftSide}>
							<div style={Style.global.h1}>You look after <span style={Style.global.primaryText}>our future</span>, so we'll look <span style={Style.global.secondaryText}>after you</span></div>
							<div style={Style.global.p}>
								You teach. You inspire. You support. You nurture. You grow. You look after our future, so we'll look after you.
							</div>
							<div style={Style.global.p}>
								We are working on a service that will help you concentrate on what you do best - teaching.
							</div>		
						</div>
						<div style={localStyles.rightSide}>
							<ReactPlayer
									style={localStyles.reactPlayer}
									url='https://player.vimeo.com/video/314861801?'
									width={310}
									loop={false}

									muted={false}
									height='100%'
									vimeoConfig={{
										playerOptions: {
											
										}
									}}
									/>
						</div>
					</div>

					<div style={localStyles.largeTopSpacer}>
					</div>
					
					<div style={localStyles.innerContent}>
						<div style={localStyles.fullWidthInner} id="join">
							<a name="join"/>
							<div style={Style.global.h1}>Join <span style={Style.global.secondaryText}>the movement</span></div>
							<div style={Style.global.p}>
								We need your help to design a service that meets the needs of teachers. A service that contains everything that matters to you.
							</div>
							<div style={Style.global.p}>
								We are talking to as many educators as we can to understand needs and pain points. We are also testing many new and exciting ideas and so would love to hear your views on them.
							</div>
							<div style={Style.global.p}>
								If you'd like to be part of the <span style={[Style.global.primaryText, Style.global.bold]}>let us teach</span> movement, enter your email below and we'll be in touch with more details.
							</div>

							<SignupButton
								onSignup={(email, gdpr) => this.onSignup(email, gdpr)}
							/>

						</div>
					</div>

					<div style={localStyles.largeTopSpacer}>
					</div>

					<div style={localStyles.innerContent}>
						<div style={localStyles.leftSide}>
							<div style={Style.global.h1}>Our <span style={Style.global.secondaryText}>ideas</span></div>
							<div style={Style.global.p}>So far we've spoken to <span style={[Style.global.primaryText, Style.global.bold]}>60</span> teachers, who have helped us come up with some initial ideas. We'd love to show you these and see what you think of them.</div>
						
							<Button label="See & rate our ideas" onClick={() => this.goToRateIdeas()}/>
						</div>
						<div style={localStyles.rightSide}>
							<img src={IdeasImage} style={localStyles.ideas}/>
						</div>
					</div>

					<div style={localStyles.largeTopSpacer}>
					</div>

					<div style={localStyles.innerContent}>
						<div style={localStyles.fullWidthInner} id="blog">
							<a name="blog"/>
							<div style={Style.global.h1}>Our <span style={Style.global.secondaryText}>blog</span></div>
							<div style={Style.global.p}>
								{BLOG_POSTS[0].summary}
							</div>
							<Button 
								label="Read the full blog..."
								onClick={() => this.goToBlog()}/>
						</div>
					</div>

					<div style={localStyles.largeTopSpacer}>
					</div>
					
					<div style={localStyles.innerContent}>
						<div style={localStyles.fullWidthInner} id="why">
							<a name="why"/>
							<div style={Style.global.h1}><span style={Style.global.secondaryText}>Why</span> are we doing this?</div>
							<div style={Style.global.p}>
								We are ambitious - we want to build a support network for teachers that is designed around what teachers really need.
							</div>
							<div style={Style.global.p}>
								We want to value teachers and recognise the critical impact they have in society and all of our futures.
							</div>
							<div style={Style.global.p}>
								We are working to create better outcomes.
								Most of all, we want to help get teachers back to what matters most - teaching.
							</div>
						</div>
					</div>
					
					<Footer/>
				</div>

				
			</div>
		)
	}
}

const localStyles = {
	container: {
		display: "flex",
		flexDirection: "column",
		
		width: "100%"
	},
	largeTopSpacer: {
		marginTop: 40,
		marginBottom: 40,
		width: "80%",
		height: 1,
		backgroundColor: Style.colors.border,
		opacity: 0.8
	},
	fullWidthInner: {
		width: "100%"
	},
	content: {
		marginTop: 50,
		
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginBottom: 20
	},
	relative: {
		position: "relative"
	},
	ideas: {
		width: 277,
		height: 325
	},
	innerContent: {
		display: "flex",
		flexDirection: "row",
		width: "80%",
		maxWidth: 800,
		"@media (max-width: 500px)": {
			flexDirection: "column"
		}
	},
	leftSide: {
		width: "50%",
		paddingRight: 20,
		"@media (max-width: 500px)": {
			paddingRight: 0,
			width: "auto"
		}
	},
	rightSide: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		width: "50%",
		paddingLeft: 20,
		"@media (max-width: 500px)": {
			marginTop: 30,
			justifyContent: "flex-start",
			paddingLeft: 0,
			width: "auto"
		}
	},
	oneLiner: {
		marginBottom: 10,
		fontSize: 18
	}
};

export default Radium(Placeholder);