// eslint-disable-next-line
const LOCAL = {
    HTTP: "http://localhost:4242"
};

// eslint-disable-next-line
const REMOTE = {
    HTTP: "https://justletusteach.herokuapp.com",
};

// ***
// Change this to change the environment
var ENVIRONMENT;

if (window.location.href.indexOf("localhost") >= 0) {
    ENVIRONMENT = LOCAL;
} else {
    ENVIRONMENT = REMOTE;
}
// ***

module.exports = {
    HTTP: ENVIRONMENT.HTTP
};