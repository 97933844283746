import React, { Component } from 'react';
import Radium from 'radium';

import TextField from "./TextField";
import DropDownField from "./DropDownField";

const FIELD_TYPE_TEXT = "text";
const FIELD_TYPE_DROPDOWN = "dropdown";

const FIELD_CONFIG = [
	{id: "name", label: "Name", placeholder: "Your name", type: FIELD_TYPE_TEXT},
	{id: "typeOfSchool", label: "What type of school do you work in?", type: FIELD_TYPE_DROPDOWN, options: [
		"Maintained",
		"Academy",
		"Independent",
		"Other"
	]},
	{id: "phase", label: "What phase of education do you cover?", type: FIELD_TYPE_DROPDOWN, options: [
		"Early years",
		"Primary",
		"Secondary",
		"College",
		"All through",
		"Other"
	]},
	{id: "position", label: "What is your position?", type: FIELD_TYPE_DROPDOWN, options: [
		"Teacher",
		"Department Head",
		"Assitant / Deputy Head",
		"Head Teacher",
		"Other"
	]},
	{id: "lengthOfService", label: "How long have you worked in education?", type: FIELD_TYPE_DROPDOWN, options: [
		"< 2 years",
		"2 - 5 years",
		"5 - 10 years",
		"> 10 years"
	]},
	{id: "location", label: "Where is  your school located", placeholder: "E.g. London, Manchester, etc.", type: FIELD_TYPE_TEXT},
	
];

class PersonalDataFields extends Component {

	render() {
		return (
			<div>
				{FIELD_CONFIG.map((field) => {
					if (field.type === FIELD_TYPE_TEXT) {
						return (
							<TextField 
								key={field.id}
								label={field.label} 
								placeholder={field.placeholder}
								value={this.props[field.id]}
								onChange={(value) => this.props.onChangeField(field.id, value)}/>

						);
					} else if (field.type === FIELD_TYPE_DROPDOWN) {
						return (
							<DropDownField 
								key={field.id}
								label={field.label}
								options={field.options}
								value={this.props[field.id]}
								onChange={(value) => this.props.onChangeField(field.id, value)}/>
						)
					} else {
						return null;
					}


				})}

			</div>
			
		);
	}
}

export default Radium(PersonalDataFields);