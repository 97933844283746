import BlogPost1 from "../components/blogposts/BlogPost1";
import BlogPost2 from "../components/blogposts/BlogPost2";
import BlogPost3 from "../components/blogposts/BlogPost3";
import BlogImage1 from  "../images/findings@2x.png";
import BlogImage2 from "../images/collaboration.png";
import BlogImage3 from "../images/screens/trainingmarketplace.png";

export default [
	{
		id: 3,
		title: "Training for all",
		content: BlogPost3,
		image: BlogImage3,
		author: "Clay",
		date: new Date(2019,2,4),
		summary: "In our first blog post, we shared with you that it's not always easy for teachers to access the training and development they need. Both new and experienced teachers don't always feel like they are given the professional support to help them thrive and grow..."
	},
	{
		id: 2,
		title: "Collaboration. Collaboration. Collaboration.",
		content: BlogPost2,
		image: BlogImage2,
		author: "Al",
		date: new Date(2019,1,26),
		summary: "The Education space is full of firms, ranging from large corporations through to niche EdTech startups, all competing to offer new products and services to those working in education..."
	},
	{
		id: 1,
		title: "Kicking things off",
		content: BlogPost1,
		image: BlogImage1,
		author: "Tom",
		date: new Date(2019,1,13),
		summary: "Over the last couple of weeks, we have been trying to understand how we can better help teachers do what teachers do best: teach! We have spoken with over 60 teachers and educators..."
	}
];