import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";
import Header from "./Header";
import ThankYouCollectDetails from "./ThankYouCollectDetails";

class ThankYou extends Component {

	render() {
		return (
			<div style={localStyles.container}>
				<Header history={this.props.history}/>
				<div style={localStyles.content}>
					<div style={Style.global.h1}>Thank <span style={Style.global.primaryText}>you</span></div>
					<div style={Style.global.p}>The feedback you've given us will really help form the future of teaching.</div>
					
					<ThankYouCollectDetails
						history={this.props.history}
					/>
					
					
				</div>
			</div>
		);
	}
}

const localStyles = {
	content: {
		padding: 50
	},
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column"
	}
};

export default Radium(ThankYou);