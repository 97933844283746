import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";

class Progress extends Component {
	render() {
		var progressStyle = [localStyles.progress, {
			width: (((this.props.currentIndex + 1) / this.props.totalScreens) * 100) + "%"
		}];
		
		return (
			<div style={localStyles.container}>
				<div style={progressStyle}></div>
			</div>
		);
	}
}

const localStyles = {
	container: {
		width: "100%",
		backgroundColor: Style.colors.lightBackground,
		height: 5
	},
	progress: {
		backgroundColor: Style.colors.primary,
		height: 5
	}
};

export default Radium(Progress);