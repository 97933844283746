import React, { Component } from 'react';
import Radium from 'radium';

import Style from "../Style";
import GdprCheckbox from './GdprCheckbox';

class SignupButton extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: "",
			gdprChecked: false
		};
	}

	onToggleGdprChecked(value) {
		this.setState({
			gdprChecked: value
		});
	}

	onClick() {
		if (this.state.email && this.state.email.length > 0) {
			this.props.onSignup(this.state.email, this.state.gdprChecked);
		}
	}

	onChangeEmail(evt) {
		this.setState({
			email: evt.target.value
		});
	}

	render() {
		return (
			<div style={localStyles.outerContainer}>
				<div style={localStyles.container}>
					<input 
						type="text" 
						style={localStyles.textField} 
						placeholder="Enter your email"
						value={this.state.email}
						onChange={(evt) => this.onChangeEmail(evt)}/>
					
					<div style={[Style.global.button, localStyles.button]} onClick={() => this.onClick()}>
						<div>Join</div>
					</div>
				</div>

				<div style={localStyles.checkboxHolder}>
					<GdprCheckbox
						checked={this.state.gdprChecked}
						onToggleChecked={(value) => this.onToggleGdprChecked(value)}
					/>
				</div>
				
				
			</div>
			
		);
	}
}

const localStyles = {
	outerContainer: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 30
	},
	checkboxHolder: {
		marginTop: 10
	},
	container: {
		display: "flex",
		width: 280,
		flexDirection: "row",
		borderRadius: 2,
		marginTop: 30,
		height: 40
	},
	textField: {
		flex: 1,
		borderStyle: "none",
		outline: "none",
		fontSize: 15,
		paddingLeft: 10,
		borderColor: Style.colors.border,
		borderWidth: 1,
		borderLeftStyle: "solid",
		borderTopStyle: "solid",
		borderBottomStyle: "solid",
		borderRightStyle: "none",
		borderTopLeftRadius: 2,
		borderBottomLeftRadius: 2
	},
	button: {
		width: 50,
		borderTopRightRadius: 2,
		borderBottomRightRadius: 2
	},
	consent: {
		marginTop: 8,
		fontSize: 12,
		opacity: 0.6
	},
	checkbox: {
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: Style.colors.border
	}
};

export default Radium(SignupButton);