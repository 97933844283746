import React, { Component } from 'react';
import Radium from 'radium';


class ScreensProgressNumber extends Component {
	render() {
		return (
			<div style={localStyles.numbers}>
				{this.props.currentIndex + 1} of {this.props.total}
			</div>
		);
	}
}

const localStyles = {
	numbers: {
		fontSize: 14,
		fontWeight: "700"
	}
};

export default Radium(ScreensProgressNumber);